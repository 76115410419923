const config = {
  siteTitle: "My Information Centre", // Site title.
  siteAuthor: "Hafid Haddouti <blog (aet) haddouti (do0ot) com>",
  siteTitleShort: "Info Center", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "My Information Centre", // Alternative site title for SEO.
  siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteUrl: "https://my.center-of.info", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "My personal space for all IT related topics, like Cloud, Cloud-Native, Kubernetes, Java etc", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteRssTitle: "My Information Centre", // Title of the RSS feed
  siteFBAppID: "", // FB Application ID for using app insights
  googleAnalyticsID: "G-N5F6GBR6GB", // GA tracking ID.
  disqusShortname: "https-haf-my-center-of-info", // Disqus shortname.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  postsPerPage: 6, // Amount of posts displayed per listing page.
  userName: "Haf", // Username to display in the author segment.
  userEmail: "blog@haddouti.com", // Email used for RSS feed's author segment
  userTwitter: "", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "Europe", // User location to display in the author segment.
  userAvatar: "", // User avatar to display in the author segment.
  userDescription:
    "Consultant, Developer, IT Architect, Cloud Specialist - and this is my personal space to talk about different IT topics, like Cloud, Kubernetes, OpenShift, Cloud-Native development, Java, nodejs and much more. " +
    "All pictures were taken be myself.", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  postDefaultCover: "defaultCover.jpg", // Set a default cover if wanted, this will be used in case a post has no cover image defined
  userLinks: [
    {
      label: "GitHub",
      url: "https://github.com/haf-tech/",
      iconClassName: "fa fa-github"
    },
    {
      label: "RSS",
      url: "/rss.xml",
      iconClassName: "fa fa-rss"
    }
  ],
  copyright: "Copyright © 2020. Hafid Haddouti", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#c62828", // Used for setting manifest and progress theme colors.
  backgroundColor: "#e0e0e0", // Used for setting manifest background color.
  template: "default"
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
